






















import Vue from "vue";
import Component from "vue-class-component";
import auth from "@/store/auth";

@Component
export default class Home extends Vue {
  email = '';
  loading = false;
  error = '';
  done = false;

  async submit() {
    this.loading = true;
    this.error = '';
    try {
      const validEmail = /^\S+@\S+$/.test(this.email);

      if (!validEmail) {
        throw Error('Please enter correct email address');
      }

      await auth.resetPassword({ email: this.email });
      this.done = true;
    } catch (err) {
      this.error = err.message;
    }
    this.loading = false;
  }
}
